
import EventService from "@/services/EventService.ts";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["id"],
  data() {
    return {
      event: null
    };
  },
  created() {
    EventService.getEvent(this.id)
      .then(response => {
        this.event = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
});
