
import { defineComponent } from "vue";

export default defineComponent({
  name: "EventCard",
  props: {
    event: {
      type: Object,
      required: true
    }
  }
});
